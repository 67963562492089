import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import shortid from "shortid";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LeaveApplicationService from "../../../Services/LeaveApplicationService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import LeaveBadge from "../../Reuseable/LeaveBadge";
import CompanyService from "../../../Services/CompanyService";
import AttendanceService from "../../../Services/AttendanceService";

const AttendanceReport = () => {
  const location = useLocation();
  const userid = DataParser.UserId();
  const compid = DataParser.CompanyId();
  const daysInMonth = DataParser.getDaysInMonth();

  const [userLeaves, setUserLeaves] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [userReport, setUserReport] = useState([]);

 
  const [selectMonth, setSelectMonth] = useState("");
  const [selectYear, setSelectYear] = useState("2025");



 

  useEffect(() => {
    let numberOfDays = ["Employee"];

    for (let i = 1; i <= daysInMonth; i++) {
      numberOfDays.push(i);
    }

    
  }, []);


  useEffect(() => {
    setLoading(true);
    AttendanceService.getAttendancebyReport(compid, "1", "2025").then((res) => {
      console.log("API Response:", res);  // Debugging log
      setUserReport(res?.users || []); // Ensure userReport is always an array
      setLoading(false);
    }).catch((err) => {
      console.error("API Fetch Error:", err);
      setUserReport([]); // Ensure state is an array even on failure
      setLoading(false);
    });
  }, [compid]);


  const getUserLeaves = (u, c) => {
    setLoading(true);
    LeaveApplicationService.userLeave(u, c).then((res) => {
      const data = res.data.userLeaves;
      setUserLeaves(data);
      setLoading(false);
    });
  };

  const handelSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    AttendanceService.getAttendancebyReport(
      compid,

      selectMonth,
      selectYear
    ).then((res) => {
      console.log("ASdasdasdasdasd: ", res);
      
      setUserReport(res?.users || []);

      const daysInJanuary = DataParser.getDaysInMonthss(
        selectYear,
        selectMonth
      );
      //setting months
      let numberOfDays = ["Employee"];
      for (let i = 1; i <= daysInJanuary; i++) {
        numberOfDays.push(i);
      }

      setSelectMonth("");
      setSelectYear("");
 
      setLoading(false);
    });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Attendance Report</h4>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus"></div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <select
                    required
                    value={selectMonth}
                    onChange={(e) => setSelectMonth(e.target.value)}
                    className="form-select"
                  >
                    <option value="">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-sm-6 col-md-3">
                  <select
                    required
                    value={selectYear}
                    onChange={(e) => setSelectYear(e.target.value)}
                    className="form-select"
                  >
                    <option value="">Select Year</option>
                    <option value="2025">2025</option>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                  </select>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="d-grid">
                    <Link
                      style={{
                        backgroundColor: "#00b5b8 !important",
                        borderColor: "#00b5b8 !important",
                        color: "white !important",
                      }}
                      to="#"
                      onClick={(e) => handelSearch(e)}
                      className="btn btn-color"
                    >
                      Search
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0  table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Employee Name</th>
                            <th>Month</th>
                            <th>Total required hours in a month</th>
                            <th>Total working hours</th>
                            <th>Less/Extra hours</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>{" "}
                            </>
                          ) : (
                            <>
                              {userReport && userReport?.map((es) => (
                                <tr key={shortid.generate()}>
                                  <td className="tableset">{es?.userName}</td>
                                  <td className="tableset">{es?.month}</td>
                                  <td className="tableset">{es?.requiredWorkingHours}</td>
                                  <td className="tableset">{es?.totalWorkingHoursDes}</td>
                                  <td className="tableset">{es?.totalLessExtraHoursDes}</td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceReport;
