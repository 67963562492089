import requests from "./httpService";

const ShiftService = {
  getShifts(id, body) {
    return requests.get(`/shift/all?company_id=${id}`, body);
  },
  createShifts(id, body) {
    return requests.post(`/shift/create?company_id=${id}`, body);
  },
  updateShifts(id, body) {
    return requests.post(`/shift/update/${id}`, body);
  },
  updateShiftsStatus(id, body) {
    return requests.post(`/shift/statusupdate/${id}`, body);
  },
};

export default ShiftService;
